<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Details of Invoice #{{ invoice.id }}</CCardTitle>
                <div class="card-header-actions" v-if="$store.getters.isAdmin">
                  <CButton
                    variant="outline"
                    type="submit"
                    color="success"
                    @click="mailToLocation"
                  >
                    <CIcon name="cil-check-circle" />Mail to location
                  </CButton>
                  <CButton
                    variant="outline"
                    type="submit"
                    color="danger"
                    @click="mailReminderToLocation"
                  >
                    <CIcon name="cil-check-circle" />Send Late Reminder
                  </CButton>
                  <CButton
                    variant="outline"
                    type="submit"
                    color="success"
                    @click="download"
                  >
                    <CIcon name="cil-check-circle" />Download
                  </CButton>
                </div>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <CSelect
                      v-if="$store.getters.isAdmin"
                      placeholder="Select"
                      label="Location"
                      :options="locations"
                      :value.sync="invoice.location_id"
                    />
                    <CInput
                      v-if="!$store.getters.isAdmin"
                      type="hidden"
                      v-model="invoice.location_id"
                    />
                  </CCol>
                  <CCol sm="6">
                    <CSelect
                      v-if="$store.getters.isAdmin"
                      placeholder="Select"
                      label="State"
                      :options="[
                        { label: 'Unpaid', value: 'unpaid' },
                        { label: 'Paid', value: 'paid' },
                        { label: 'Reminded', value: 'reminded' },
                      ]"
                      :value.sync="invoice.state"
                    />
                    <CInput
                      v-if="!$store.getters.isAdmin"
                      type="hidden"
                      v-model="invoice.location_id"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Invoice Rows</CCardSubtitle>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="12" class="add-btn">
                    <CButton
                      variant="outline"
                      type="submit"
                      color="warning"
                      @click="addInvoiceRow()"
                    >
                      <CIcon name="cil-playlist-add" />Add Row
                    </CButton>
                  </CCol>

                  <br />

                  <CContainer
                    v-for="(invoice_row, index) in invoice.invoice_rows"
                    id="invoiceRowsContainer"
                  >
                    <CRow>
                      <CCol sm="1">
                        <CButton
                          class="close-btn"
                          variant="outline"
                          type="submit"
                          size="sm"
                          color="danger"
                          @click="deleteInvoiceRow(index)"
                        >
                          <CIcon class="close-btn-icon" name="cil-trash" />
                        </CButton>
                      </CCol>
                      <CCol sm="4">
                        <CInput
                          name="description"
                          label=""
                          type="text"
                          label="Description"
                          placeholder="Description"
                          v-model="invoice_row.description"
                        />
                      </CCol>
                      <CCol sm="3">
                        <CInput
                          name="description"
                          type="number"
                          label="Tax"
                          prepend="%"
                          v-model="invoice_row.tax_percentage"
                        />
                      </CCol>
                      <CCol sm="3">
                        <CInput
                          name="price_inc"
                          type="number"
                          label="Price Inc"
                          prepend="€"
                          v-model="invoice_row.price_inc"
                        />
                      </CCol>
                    </CRow>
                  </CContainer>
                </CRow>
              </CCardBody>
            </CCard>

            <CCard>
              <CCardHeader>
                <CCardSubtitle>Summary</CCardSubtitle>
              </CCardHeader>

              <CCardBody class="summary">
                <CRow>
                  <CContainer>
                    <CRow>
                      <CCol sm="2">
                        <p>Price ex.</p>
                        <strong>€{{ invoice.total_price_ex }}</strong>
                      </CCol>
                      <CCol sm="2">
                        <p>Tax</p>
                        <strong>€{{ invoice.total_price_tax }}</strong>
                      </CCol>
                      <CCol sm="2">
                        <p>Price inc.</p>
                        <strong>€{{ invoice.total_price_inc }}</strong>
                      </CCol>
                    </CRow>
                  </CContainer>
                </CRow>
              </CCardBody>
            </CCard>

            <CButton
              v-if="$store.getters.isAdmin"
              variant="outline"
              type="submit"
              color="primary"
              @click="updateInvoice"
            >
              <CIcon name="cil-check-circle" />Update
            </CButton>
            <CButton
              v-if="$store.getters.isAdmin"
              class="float-right"
              variant="outline"
              type="submit"
              color="danger"
              @click="deleteInvoice"
            >
              <CIcon name="cil-trash" /> Delete
            </CButton>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import InvoiceAPI from "/app/src/api/invoice.js";
import LocationAPI from "/app/src/api/location.js";

export default {
  name: "Create",
  data: () => {
    return {
      locationAPI: new LocationAPI(),
      invoiceAPI: new InvoiceAPI(),
      locations: [],
      invoice: {
        invoice_rows: [],
      },
      customers: [],
    };
  },
  created: function () {
    console.log("created");
    this.getLocations();
    this.getInvoice(this.$route.params.id);
  },
  methods: {
    getInvoice: function (id) {
      this.$store.dispatch("loading");
      let self = this;
      self.invoiceAPI
        .detail(id)
        .then((invoice) => {
          this.$store.dispatch("stopLoading");
          self.invoice = invoice;
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
          this.$alert.show({ type: "danger", message: error });
        });
    },

    getLocations: function () {
      this.$store.dispatch("loading");
      let self = this;
      this.locationAPI
        .all()
        .then((locations) => {
          self.$store.dispatch("stopLoading");
          for (let i = 0; i < locations.length; i++) {
            self.locations.push({
              label: locations[i].name,
              value: locations[i].id,
            });
          }
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },

    updateInvoice: function () {
      this.$store.dispatch("loading");

      this.invoiceAPI
        .update(this.invoice)
        .then((result) => {
          this.$store.dispatch("stopLoading");
          this.$store.dispatch("alert", {
            message: "Invoice has been updated",
            type: "success",
          });
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },

    addInvoiceRow: function () {
      this.invoice.invoice_rows.push({
        description: "",
      });
    },

    deleteInvoice: function () {
      const params = {
        title: "Confirm",
        text: "Are you sure you want to delete?",
        onConfirm: () => {
          this.$store.dispatch("loading");
          this.invoiceAPI
            .delete(this.$route.params.id)
            .then((result) => {
              this.$store.dispatch("stopLoading");
              this.$router.push({ path: "/manager/invoices" });
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch("stopLoading");
              this.$alert.show({ type: "danger", message: error });
            });
        },
      };
      this.$confirm.show(params);
    },

    deleteInvoiceRow(index) {
      this.invoice.invoice_rows.splice(index, 1);
    },

    mailToLocation() {
      this.$store.dispatch("loading");
      this.invoiceAPI
        .mailToLocation(this.invoice.id)
        .then((result) => {
          this.$store.dispatch("stopLoading");
          this.$alert.show({
            type: "success",
            message: "Invoice has been mailed",
          });
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },

    mailReminderToLocation() {
      this.$store.dispatch("loading");
      this.invoiceAPI
        .mailReminderToLocation(this.invoice.id)
        .then((result) => {
          this.$store.dispatch("stopLoading");
          this.$alert.show({
            type: "success",
            message: "Invoice has been mailed",
          });
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },

    download() {
      this.$store.dispatch("loading");
      this.invoiceAPI
        .download(this.invoice.id)
        .then((result) => {
          this.$store.dispatch("stopLoading");
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },

    formatNumber(val, decimals) {
      //Parse the value as a float value
      val = parseFloat(val);
      //Format the value w/ the specified number
      //of decimal places and return it.
      return parseFloat(val.toFixed(decimals));
    },
  },
  watch: {
    "invoice.invoice_rows": {
      // This will let Vue know to look inside the array
      deep: true,
      // We have to move our method to a handler field
      handler(val) {
        console.log("changed");

        // Reset values
        this.invoice.total_price_ex = 0;
        this.invoice.total_price_tax = 0;
        this.invoice.total_price_inc = 0;

        // Calculate new prices
        for (let i = 0; i < this.invoice.invoice_rows.length; i++) {
          let row = this.invoice.invoice_rows[i];
          this.invoice.total_price_tax += this.formatNumber(
            (row.price_inc / (100 + this.formatNumber(row.tax_percentage, 2))) *
              this.formatNumber(row.tax_percentage, 2),
            2
          );
          this.invoice.total_price_inc += this.formatNumber(row.price_inc, 2);
          this.invoice.total_price_ex = this.formatNumber(
            this.formatNumber(this.invoice.total_price_inc, 2) -
              this.formatNumber(this.invoice.total_price_tax, 2),
            2
          );
        }
      },
    },
  },
};
</script>

<style scoped>
.add-btn {
  margin-bottom: 20px !important;
}

.summary {
  margin-bottom: 20px;
}

.close-btn-icon {
  margin: 0;
}

.close-btn {
  margin-top: 30px;
}

#invoiceRowsContainer .btn-outline-danger {
  margin-top: 35px;
}
</style>
